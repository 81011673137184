/* You can add global styles to this file, and also import other style files */
@import "assets/esg-theme/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "assets/custom-icon/style.css";

* {
  box-sizing: border-box
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: #EFF3F8;
  color: #374151
}

h1,
h2,
h3,
h4,
p {
  margin: 0
}

.p-password,
.p-password-input,
.p-multiselect,
.p-inputnumber,
.p-dropdown,
.p-calendar,
.p-inputtext {
  width: 100%
}

a,
a:link,
a:visited {
  text-decoration: none;
}

.p-overlay {
  position: absolute
}

.p-menu {
  border: unset
}

.p-tooltip-text {
  white-space: nowrap;
  width: fit-content;
}

.p-datatable-thead,
.actions {
  white-space: nowrap;
}

.p-dialog-content {
  overflow-y: inherit !important
}

.p-component-overlay {
  overflow: auto !important
}

.success {
  color: var(--success)
}

.warning {
  color: var(--warning)
}

.error {
  color: var(--error)
}

.info {
  color: var(--primary-color)
}

.bg-success {
  background-color: var(--success)
}

.ma-background {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.ma-success {
  @extend .ma-background;
  background-color: rgba(0, 255, 94, 0.5);
}


.bg-warning {
  background-color: var(--warning)
}

.ma-warning {
  @extend .ma-background;
  background-color: rgba(255, 230, 0, 0.5)
}

.bg-error {
  background-color: var(--error)
}

.ma-error {
  @extend .ma-background;
  background-color: rgba(226, 76, 76, 0.5)
}


.bg-info {
  background-color: var(--primary-color)
}

.sticky-bar {
  height: auto;
  width: calc(100% + 48px);
  position: sticky;
  bottom: -24px;
  margin-left: -24px;
  margin-bottom: -24px;
  background-color: white;
  padding: 16px;
  margin-top: auto;
  z-index: 1000;
}

::ng-deep {
  .p-menuitem-link-active {
    background: red;
  }
}


